export type PhysicalInfoSession = {
  location: {
    name: string
    street: string
    postalCode: number
    city: string
  }
  type: InfoSessionType.PHYSICAL
  doors?: `${number}.${number}` // hh.mm
}

export type OnlineInfoSession = {
  location?: never
  doors?: never
  type: InfoSessionType.ONLINE
}

export type CommonInfoSessionProps = {
  date: `${number}-${number}-${number}` // yyyy-mm-dd
  from: `${number}.${number}` // hh.mm
  to: `${number}.${number}` // hh.mm
  url: string
}

export type InfoSession = CommonInfoSessionProps & (PhysicalInfoSession | OnlineInfoSession)

export enum InfoSessionType {
  PHYSICAL = 'physical',
  ONLINE = 'online'
}
