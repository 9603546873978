export type LoadingSkeletonProps = {
  className?: string
  as?: keyof Pick<HTMLElementTagNameMap, 'div' | 'section' | 'ul' | 'li'>
  variant?: LoadingSkeletonColorVariant
  gap?: number
}

export enum LoadingSkeletonColorVariant {
  DEFAULT = 'default',
  INVERSE = 'inverse'
}
