import classNames from 'classnames'
import headerStyles from 'components/header/Header.module.scss'
import styles from 'features/energy-gardens/common/header/Header.module.scss'
import { Language } from 'types/language'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { EnergyGardenHeaderProps } from 'features/energy-gardens/common/header/types'
import { Logo } from 'assets/svg'
import { PropsWithChildren } from 'react'
import HeaderTag from 'features/energy-gardens/common/header/HeaderTag'

const Header = ({ badge, homePageLink, children, isFixed }: PropsWithChildren<EnergyGardenHeaderProps>) => {
  // Router
  const router = useRouter()
  const { locale } = router

  return (
    <header className={classNames('container', headerStyles.header, styles['energy-gardens-header'], { [styles.fixed]: isFixed })}>
      <nav className={classNames(headerStyles.menu, styles.menu)}>
        <Link href={homePageLink} className={headerStyles.logo} aria-label={locale === Language.FRENCH ? 'accueil' : 'home'}>
          <Logo />
        </Link>

        {badge && (
          <>
            {badge?.asLink ? (
              <Link className={headerStyles['badge-link']} href={homePageLink}>
                <HeaderTag {...badge} />
              </Link>
            ) : (
              <HeaderTag {...badge} />
            )}
          </>
        )}

        {!!children && children}
      </nav>
    </header>
  )
}

export default Header
