const SunIcon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.25 12C5.25 8.27208 8.27208 5.25 12 5.25C15.7279 5.25 18.75 8.27208 18.75 12C18.75 15.7279 15.7279 18.75 12 18.75C8.27208 18.75 5.25 15.7279 5.25 12Z"
      fill="currentcolor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 1.25C12.4142 1.25 12.75 1.58579 12.75 2L12.75 4C12.75 4.41421 12.4142 4.75 12 4.75C11.5858 4.75 11.25 4.41421 11.25 4L11.25 2C11.25 1.58579 11.5858 1.25 12 1.25Z"
      fill="currentcolor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 19.25C12.4142 19.25 12.75 19.5858 12.75 20L12.75 22C12.75 22.4142 12.4142 22.75 12 22.75C11.5858 22.75 11.25 22.4142 11.25 22L11.25 20C11.25 19.5858 11.5858 19.25 12 19.25Z"
      fill="currentcolor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.25 12C1.25 11.5858 1.58579 11.25 2 11.25L4 11.25C4.41421 11.25 4.75 11.5858 4.75 12C4.75 12.4142 4.41421 12.75 4 12.75L2 12.75C1.58579 12.75 1.25 12.4142 1.25 12Z"
      fill="currentcolor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19.25 12C19.25 11.5858 19.5858 11.25 20 11.25L22 11.25C22.4142 11.25 22.75 11.5858 22.75 12C22.75 12.4142 22.4142 12.75 22 12.75L20 12.75C19.5858 12.75 19.25 12.4142 19.25 12Z"
      fill="currentcolor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.39862 19.6015C4.10573 19.3086 4.10573 18.8337 4.39862 18.5408L5.81284 17.1266C6.10573 16.8337 6.58061 16.8337 6.8735 17.1266C7.16639 17.4195 7.16639 17.8944 6.8735 18.1873L5.45929 19.6015C5.16639 19.8944 4.69152 19.8944 4.39862 19.6015Z"
      fill="currentcolor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.1264 6.87348C16.8335 6.58059 16.8335 6.10571 17.1264 5.81282L18.5406 4.3986C18.8335 4.10571 19.3084 4.10571 19.6013 4.3986C19.8942 4.6915 19.8942 5.16637 19.6013 5.45926L18.1871 6.87348C17.8942 7.16637 17.4193 7.16637 17.1264 6.87348Z"
      fill="currentcolor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19.6014 19.6014C19.3085 19.8943 18.8336 19.8943 18.5407 19.6014L17.1265 18.1872C16.8336 17.8943 16.8336 17.4194 17.1265 17.1265C17.4194 16.8336 17.8943 16.8336 18.1872 17.1265L19.6014 18.5407C19.8943 18.8336 19.8943 19.3085 19.6014 19.6014Z"
      fill="currentcolor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.8736 6.87359C6.58071 7.16649 6.10583 7.16649 5.81294 6.87359L4.39873 5.45938C4.10583 5.16649 4.10583 4.69161 4.39873 4.39872C4.69162 4.10583 5.16649 4.10583 5.45939 4.39872L6.8736 5.81293C7.16649 6.10582 7.16649 6.5807 6.8736 6.87359Z"
      fill="currentcolor"
    />
  </svg>
)

export default SunIcon
