import { EnergyGardens } from 'features/energy-gardens/types'
import { contact, faq, homePage, howItWorks, infoSession, terms } from 'features/energy-gardens/landingpage/routing/brugse-zonnetuin'
import { InfoSession, InfoSessionType } from 'features/energy-gardens/landingpage/views/info-session/types'

export const MAIN_CONTAINER_ID = 'main'

export const NAVIGATION = {
  [EnergyGardens.BRUGSE_ZONNETUIN]: {
    fr: [homePage, howItWorks, faq, terms, infoSession, contact],
    nl: [homePage, howItWorks, faq, terms, infoSession, contact]
  }
}

export const SOLAR_PANEL_KWH_PRODUCTION = 530
export const SOLAR_PANEL_YEARLY_PROFIT = 20

export const INFO_SESSIONS: InfoSession[] = [
  {
    date: '2024-04-25',
    from: '19.00',
    to: '20.00',
    type: InfoSessionType.ONLINE,
    url: 'https://tally.so/r/n9WKQ5'
  },
  {
    date: '2024-05-13',
    from: '19.00',
    to: '20.00',
    type: InfoSessionType.ONLINE,
    url: 'https://tally.so/r/3qbbld'
  },
  {
    date: '2024-05-22',
    from: '19.00',
    location: {
      city: 'Brugge',
      name: 'De Balsemboom',
      postalCode: 8000,
      street: 'Ganzenstraat 33'
    },
    to: '20.30',
    type: InfoSessionType.PHYSICAL,
    url: 'https://tally.so/r/woAoOb'
  }
]
