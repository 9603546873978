import { EnergyGardenHeaderProps } from 'features/energy-gardens/common/header/types'
import Tag from 'components/tag/Tag'
import styles from './Header.module.scss'

const HeaderTag = ({ icon, text }: EnergyGardenHeaderProps['badge']) => (
  <Tag className={styles.badge}>
    {!!icon && icon}
    {text}
  </Tag>
)

export default HeaderTag
