import { EnergyGardens } from 'features/energy-gardens/types'
import { routes } from 'utils/routes'
import { CONTENT_IDS } from 'features/energy-gardens/landingpage/routing/types'
import { toHash } from 'features/energy-gardens/landingpage/routing/utils'

export const homePage = {
  page: {
    href: routes('nl', EnergyGardens.BRUGSE_ZONNETUIN).energyGarden,
    text: 'Over dit project'
  },
  contentLinks: [
    {
      href: toHash(CONTENT_IDS.INTRO),
      text: 'Intro'
    },
    {
      href: toHash(CONTENT_IDS.WHAT),
      text: 'Wat'
    },
    {
      href: toHash(CONTENT_IDS.FOR_WHOM),
      text: 'Voor wie'
    },
    {
      href: toHash(CONTENT_IDS.WHY),
      text: 'Waarom'
    },
    {
      href: toHash(CONTENT_IDS.PARTICIPATE),
      text: 'Meedoen'
    },
    {
      href: toHash(CONTENT_IDS.ADVANTAGES),
      text: 'Voordelen'
    },
    {
      href: toHash(CONTENT_IDS.LOCATION),
      text: 'De locatie'
    },
    {
      href: toHash(CONTENT_IDS.ABOUT_BOLT),
      text: 'Over Bolt'
    }
  ]
}

export const howItWorks = {
  page: {
    href: routes('nl', EnergyGardens.BRUGSE_ZONNETUIN).energyGardenHowItWorks,
    text: 'Hoe werkt het'
  },
  contentLinks: [
    {
      href: toHash(CONTENT_IDS.INFOGRAPHIC),
      text: 'Infografiek'
    },
    {
      href: toHash(CONTENT_IDS.SOLAR_PANEL_PLACEMENT),
      text: 'Zonnepanelen leggen'
    },
    {
      href: toHash(CONTENT_IDS.ENERGY_EXCHANGE),
      text: 'Stroom uitwisselen'
    }
  ]
}

export const faq = {
  page: {
    href: routes('nl', EnergyGardens.BRUGSE_ZONNETUIN).energyGardenFaq,
    text: 'Veelgestelde vragen'
  },
  contentLinks: [] // generated dynamically based on the faq items from CMS
}

export const terms = {
  page: {
    href: routes('nl', EnergyGardens.BRUGSE_ZONNETUIN).energyGardenTerms,
    text: 'Investering'
  },
  contentLinks: [
    {
      href: toHash(CONTENT_IDS.INVESTMENT_EXPLAINED),
      text: 'Uitgelegd'
    },
    {
      href: toHash(CONTENT_IDS.INVESTMENT_DETAILS),
      text: 'Details'
    }
  ]
}

export const infoSession = {
  page: {
    href: routes('nl', EnergyGardens.BRUGSE_ZONNETUIN).energyGardenInfoSession,
    text: 'Infomoment'
  },
  contentLinks: [
    {
      href: toHash(CONTENT_IDS.INTRO),
      text: 'Intro'
    },
    {
      href: toHash(CONTENT_IDS.PROGRAM),
      text: 'Programma'
    },
    {
      href: toHash(CONTENT_IDS.GROUP_INSCRIPTION),
      text: 'Infomomenten in groep'
    }
  ]
}

export const contact = {
  page: {
    href: routes('nl', EnergyGardens.BRUGSE_ZONNETUIN).energyGardenContact,
    text: 'Contact'
  },
  contentLinks: [
    {
      href: toHash(CONTENT_IDS.CONTACT_FORM),
      text: 'Formulier'
    }
  ]
}
