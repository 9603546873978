export enum CONTENT_IDS {
  INTRO = 'intro',
  WHAT = 'wat',
  FOR_WHOM = 'voor-wie',
  WHY = 'waarom',
  PARTICIPATE = 'meedoen',
  ADVANTAGES = 'voordelen',
  LOCATION = 'de-locatie',
  ABOUT_BOLT = 'over-bolt',
  INFOGRAPHIC = 'infografiek',
  SOLAR_PANEL_PLACEMENT = 'zonnepanelen-leggen',
  ENERGY_EXCHANGE = 'stroom-uitwisselen',
  INVESTMENT_DETAILS = 'details-van-de-investering',
  INVESTMENT_EXPLAINED = 'investering-uitgelegd',
  PROGRAM = 'programma',
  GROUP_INSCRIPTION = 'infomomenten-in-groep',
  CONTACT_FORM = 'formulier'
}
