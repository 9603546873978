import { EnergyGardens } from 'features/energy-gardens/types'
import { Language } from 'types/language'

export const SOLAR_PANEL_AMOUNTS: { [key in EnergyGardens]: number[] } = {
  [EnergyGardens.BRUGSE_ZONNETUIN]: [4, 8, 12, 16]
}

// TODO: make multi-language
export const ENERGY_GARDENS: { [garden in EnergyGardens]: { [Language.DUTCH]: string } } = {
  [EnergyGardens.BRUGSE_ZONNETUIN]: {
    [Language.DUTCH]: 'brugse-zonnetuin'
    // [Language.FRENCH]: 'jardin-solaire-brugeois'
  }
}

// Phases: number of solar panels per phase
export const ENERGY_GARDEN_PHASES: { [garden in EnergyGardens]: { [key: number]: number } } = {
  [EnergyGardens.BRUGSE_ZONNETUIN]: {
    1: 858,
    2: 849,
    3: 857,
    4: 857
  }
}

export const PDF_FILES = {
  INFORMATION_NOTE: 'https://files.boltenergie.be/energy-gardens/brugse-zonnetuin/brugse-zonnetuin-informatie-nota.pdf',
  INFORMATION_NOTE_ADDENDUM_ONE:
    'https://files.boltenergie.be/energy-gardens/brugse-zonnetuin/brugse-zonnetuin-informatie-nota-addendum-20240321.pdf',
  INFORMATION_NOTE_ADDENDUM_TWO:
    'https://files.boltenergie.be/energy-gardens/brugse-zonnetuin/brugse-zonnetuin-informatie-nota-addendum-20240430.pdf',
  INFORMATION_NOTE_ADDENDUM_THREE:
    'https://files.boltenergie.be/energy-gardens/brugse-zonnetuin/brugse-zonnetuin-informatie-nota-addendum-20240625.pdf',
  LOAN_TERMS: 'https://files.boltenergie.be/energy-gardens/brugse-zonnetuin/brugse-zonnetuin-lenings-voorwaarden.pdf'
}
